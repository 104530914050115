/* Fullscreen Loader Container */
.registration-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Ensure it covers the full viewport */
  background-color: rgba(255, 255, 255, 0.9); /* Subtle overlay effect */
  z-index: 1000; /* Ensure it's above all content */
  overflow: hidden; /* Prevent scrolling while loading */
}

/* Inner Content Wrapper */
.registration-loading-inner {
  text-align: center;
  width: 100%;  /* Make sure it takes full width */
  padding: 20px;
  margin: 0 20px; /* Add some padding for smaller screen responsiveness */
  box-sizing: border-box;
}

/* Logo Styling */
.loading-logo {
  width: 120px;  /* Adjust the logo size to fit well on the screen */
  height: auto;
  margin-bottom: 20px;
  object-fit: contain; /* Ensures the logo maintains its aspect ratio */
}

/* Message Styling */
.loading-message {
  margin-top: 20px;
}

/* Step Loader container */
.step-loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;  /* Center vertically */
  align-items: center;  /* Center horizontally */
  height: 100%;  /* Full height of the parent container */
  padding: 5px 20px;
  overflow: hidden;  /* Prevent scrolling */
  text-align: center;
}

/* Step indicator container */
.step-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Consistent spacing between steps */
  width: 100%;
  max-width: 600px; /* Limit max width for better visibility */
  margin-bottom: 20px;
}

/* Individual step wrapper */
.step {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Circle for step number/icon */
.circle {
  width: 60px; /* Increased size for better visibility */
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #bbb; /* Default background */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Icon inside the circle */
.icon {
  font-size: 24px; /* Icon size */
}

/* Line connecting steps */
.step-line {
  width: 50px;
  height: 6px;
  background-color: #bbb; /* Default line color */
  margin-left: 10px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

/* Active step styles */
.highlighted-step {
  background-color: #4caf50; /* Highlight active step */
  transform: scale(1.1); /* Slightly enlarge */
}

/* Completed step styles */
.completed {
  background-color: #2196f3; /* Different color for completed steps */
}

/* Styling for step descriptions */
.step-info {
  text-align: center;
  margin-top: 30px; /* Space below the steps */
  font-size: 18px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 600px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.step-info h3 {
  font-size: 24px;
  margin: 10px 0;
  color: #333;
}

.step-info p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  opacity: 1;
  transition: opacity 0.3s ease;
}

input[type="text"], input[type="password"], input[type="email"]{
  height:auto;
  min-height:1.4375em;
}

.search_ship_port input[type="text"] {
    height: 40px;
    padding: 0 40px;
}

.css-bkzwk2-MuiStack-root{
  overflow: visible !important;
}

.MuiInputLabel-root {
  z-index:99 !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .registration-loading-inner {
    max-width: 90%;  /* Make the inner container fit smaller screens */
    padding: 15px;
  }

  .step-indicator {
    flex-wrap: wrap; /* Steps wrap to the next line */
  }

  .step-line {
    width: 40px;
  }

  .circle {
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
}


