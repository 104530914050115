

.react-form-builder .react-form-builder-preview {
  width: 75%;
  position: relative;
}

.react-form-builder .react-form-builder-toolbar {
  width: 20%;
  margin-left: 30px;
}

.react-form-builder > div {
  display: flex;
}